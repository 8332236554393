import { ceping, api } from '@/api'
import { config } from '@/utils'
import wx from 'weixin-js-sdk';
export default {
    name: 'Ceping',

    mounted () {
        this.$toast.loading({ message: '加载中...', forbidClick: true })
    },

    activated () {
        this.handleShare()  // 分享配置
    },

    methods: {
        // 获取数据
        handleGetData () {
            let options = {
                page: this.page
            };
            if (this.$route.query.cp) options.cp = this.$route.query.cp
            ceping.index(options).then(res => {
                this.$toast.clear()
                this.isAjax = false
                this.page++
                if(res.status != 1) return this.$toast(res.message)
                this.loading = false
                this.isFinished = res.data.lists.current_page >= res.data.lists.last_page
                this.bannerItems = res.data.banner
                this.hotItems = res.data.hot
                this.listItems = this.listItems.concat(res.data.lists.data)
            }).catch(err => {
                this.$toast.clear()
                this.isAjax = false
                console.log(err)
            })
        },

        // 分享
        async handleShare () {
            // console.log(location.href.split('#')[0], process.env.VUE_APP_DEBUG == 1 ? true : false)
            // if (!_global.wx || process.env.VUE_APP_CONFIG != 'test') return
            if (!_global.wx) return
            let url = location.href.split('#')[0];
            let res = await api.share({url: encodeURIComponent(url)})
            let options = {
                debug: process.env.VUE_APP_DEBUG == 1 ? true : false,
                appId: res.appId,
                timestamp: res.timestamp,
                nonceStr: res.nonceStr,
                signature: res.signature,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
            };
            wx.config(options);
           
            wx.ready(function () {
                // 分享好友
                wx.updateAppMessageShareData({
                    title: '灵鸽心理测评',                                // 分享标题
                    desc: '来给你的心理健康做个全面体检-灵鸽心理',
                    link: url,                                          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致且分享的地址不要带端口
                    imgUrl:  `${config.apiurl}/uploads/logo.png`,       // 分享图标
                    success: function (e) {
                      console.log('分享好友设置成功')
                    }
                });
                
                // 分享朋友圈
                wx.updateTimelineShareData({ 
                    title: '灵鸽心理测评',                                 // 分享标题
                    link: url,                                           // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致且分享的地址不要带端口
                    imgUrl:  `${config.apiurl}/uploads/logo.png`,        // 分享图标
                    success: function (e) {
                        console.log('分享朋友圈设置成功')
                    }
                });
            })
        }
    },

    data () {
        return {
            isAjax: false,
            loading: true,
            isFinished: false,
            page: 1,
            bannerItems: [],
            listItems: [],
            hotItems: [],
            shareImg: `${config.apiurl}/uploads/logo.png`,
            shareTitle: '灵鸽心理测评',
            shareDesc: '来给你的心理健康做个全面体检-灵鸽心理'
        }
    }

}